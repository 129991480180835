/*Fill latest sermon column with image*/
.latestSermon-img{
    width: 100% !important;
    height: 100% !important;
  }

/*Fix for overflowing text on gmaps*/
.gm-style-iw {
    width: auto !important;
    max-width: 180px;
    text-align: center;
   }

   
/*Font color for gmap in footer*/
   #content-map-footer>h4{
    color: #2b2b2b;
   }
   #content-map-footer>p{
    color: #777;
   }

   /*Hide map type toggle and street view selection from footer map*/
   #map_canvas_footer>div>div> .gm-style-mtc , #map_canvas_footer>div>div>div>.gm-svpc{
       display: none;
   }   

   .text-center {
    text-align: center;
  }
  
  .image-center {
    display: block;
      margin-left: auto;
      margin-right: auto;
  }

  /*Styling for the person model*/
  .person-name{
    font-size: 24px;
  margin-bottom: 8px;
  margin-top: 10px;
  font-weight: 300;
  line-height: 24px;
  }
  
  .person-title{
    font-size: 16px;
    color: #a2a5a4;
    text-transform: uppercase;
    margin-bottom: 50px;
  }

  @media (min-width: 500px){
    .logo  {
     max-height: 155px;
    }}



  /*Mobile menu colour*/
  .nestor-main-menu .navbar-toggle{
    border: 1px solid #c79f6e !important;
    background: #c79f6e !important;
    color: #fff !important;
}

.navbar-header{
text-align: center;
width:100%;
}
@media (min-width:768px) and (max-width:979px){
.navbar-toggle{
  display: inline-block;
  width: 250px;
  border-radius: 0;
  padding: 12px;
  float: none;   
}
.nav-collapse{
  margin-top: -55px !important;
}
}

@media (max-width: 767px) {
  .nestor-main-menu .navbar-toggle {
    width: 100%;
    max-width: 250px;
  }

  .nav-collapse{
    margin-top: -55px !important;
  }
}

  /*Styling for sermon load more and search*/
.sermon-load-more, .sermon-search-button {
    padding: 2px 35px !important;
  }


  /*Change btn border color to fit theme*/
  
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
    border-color: #c79f6e !important;
    background-color: #c79f6e !important;
  }
  
  .btn-primary{
    border-color: #c79f6e;
  }




/* Site Text color if desired to be black*/

/*body{
  color: #000;
}*/

.breadcrumbs>p{
  line-height: 2.1em;
}

/*Notification banner*/
.notification-banner{
  background: #27479d;
  display: block;
  text-align: center;
  padding: 15px;
  color: #fff;
}

.notification-banner-text{
  font-size: 16px;
  font-family: "Roboto Slab",sans-serif;
  font-weight: 400;
  line-height: 50px;
  color: #c59c6d;
}

.notification-banner-link{
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin: auto;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 11px 23px;
  border: 2px solid;
  border-color: #c59c6d;
  color: #c59c6d;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 10px;
}

.notification-banner-link:hover{
  border-color: #c59c6d;
  background-color: #c59c6d;
  color: #fff;
}